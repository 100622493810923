import { UploadedFileContext } from "@camberi/firecms";

export const fileName = (ctx: UploadedFileContext): string => {
  const suffix = (Date.now() + "" + ~~(100 * Math.random())).slice(-6);
  const { file: { name } } = ctx;
  const extIx = name.lastIndexOf(".");
  const origName = name.slice(0, extIx);
  const ext = name.slice(extIx).replace(".jpeg", ".jpg");

  return `${origName}_${suffix}${ext}`
}
