import {
  buildCollection,
  EntityCollection,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";
import type { Painting, PortfolioItem } from "types";
import { default as paintingSchema } from "../schema/painting";
import { default as categorySchema, categoryCallbacks } from "../schema/category";
import bookSchema from "../schema/book";
import pageSchema from "../schema/page";
import orderSchema from "../schema/order";
import { default as portfolioItemSchema } from "../schema/portfolioItem";
import { default as portfolioCategorySchema } from "../schema/portfolioCategory";
import { default as printSchema, printCallbacks } from "../schema/print";
import contactInformationColumn from "../columns/contactInformationColumn";
import orderDetailsColumn from "../columns/orderDetailsColumn";
import createdAtAdditionalColumn from "columns/createdAtColumn";

export const navigation: NavigationBuilder = async ({ user, authController }: NavigationBuilderProps) => {

  const paintingCollection: EntityCollection<Painting> =
    buildCollection({
      name: "Paintings",
      path: "paintings",
      textSearchEnabled: true,
      // initialSort: ["sortOrder", "desc"],
      initialSort: ["date", "desc"],
      inlineEditing: false,
      schema: paintingSchema,
      defaultSize: "m"
    });
  
  const portfolioItemCollection: EntityCollection<PortfolioItem> =
    buildCollection({
      name: "Portfolio Items",
      path: "portfolioItems",
      textSearchEnabled: true,
      initialSort: ["sortOrder", "desc"],
      inlineEditing: false,
      schema: portfolioItemSchema,
      defaultSize: "m"
    });

  return ({
    collections: [
      buildCollection({
        path: "categories",
        schema: categorySchema,
        callbacks: categoryCallbacks,
        name: "Paintings",
        initialSort: ["sortOrder", "desc"],
        inlineEditing: false,
        selectionEnabled: false,
        description: "Manage Paintings and Categories",
        textSearchEnabled: true,
        permissions: ({ authController }) => ({
          edit: true,
          create: true,
          delete: true
        }),
        subcollections: [paintingCollection]
      }),

      buildCollection({
        path: "books",
        schema: bookSchema,
        textSearchEnabled: true,
        description: "Manage Books",
        initialSort: ["sortOrder", "desc"],
        name: "Books",
        inlineEditing: false
      }),

      buildCollection({
        path: "pages",
        schema: pageSchema,
        description: "Manage Pages",
        name: "Pages",
        inlineEditing: false
      }),

      buildCollection({
        path: "prints",
        schema: printSchema,
        description: "Manage Prints",
        name: "Prints",
        textSearchEnabled: true,
        callbacks: printCallbacks,
        inlineEditing: false
      }),

      buildCollection({
        path: "portfolioCategories",
        schema: portfolioCategorySchema,
        description: "Manage Portfolio",
        initialSort: ["sortOrder", "desc"],
        name: "Portfolio",
        textSearchEnabled: true,
        inlineEditing: false,
        subcollections: [portfolioItemCollection]
      }),

      buildCollection({
        path: "orders",
        schema: orderSchema,
        description: "Manage Orders",
        name: "Orders",
        textSearchEnabled: false,
        inlineEditing: false,
        initialSort: ["createdAt", "desc"],
        defaultSize: "xl",
        permissions: ({ authController }) => ({
          edit: true,
          create: false,
          delete: false
        }),
        additionalColumns: [
          contactInformationColumn,
          orderDetailsColumn,
          createdAtAdditionalColumn
        ],
        properties: ["contactInformation", "orderDetails", "createdAtCustom",]
      }),
    ]
  });
};
