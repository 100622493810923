import { buildProperty, buildSchema } from "@camberi/firecms";
import type { PortfolioItem } from "types";
import { fileName } from "../helpers";
import SummaryPreview from "../components/SummaryPreview";
import IllustrationsPreview from "../components/IllustrationsPreview";
import YearPreview from "components/YearPreview";

export default buildSchema<PortfolioItem>({
  name: "PortfolioItem",
  properties: {
    title: {
      title: "Title",
      validation: { required: true },
      dataType: "string"
    },
    slug: {
      title: "Slug",
      validation: { required: true },
      description: "Unique URL-friendly identifier. For example, for the book 'Monstera Brand Identity' the slug would be 'monstera-brand-identity'.",
      dataType: "string"
    },
    image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
      title: "Image",
      dataType: "string",
      validation: { required: true },
      description: "Cover Image showing on the portfolio category page",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "upload/portfolio",
          acceptedFiles: ["image/*"],
          fileName
        },
      }
    }),
    summary: {
      title: "Summary",
      validation: { required: true },
      description: "Portfolio Item Summary",
      dataType: "string",
      config: {
        markdown: true,
        Preview: SummaryPreview
      }
    },
    // date: {
    //   title: "Year",
    //   description: "Date (year) created",
    //   validation: { required: false },
    //   dataType: "timestamp",
    //   config: {
    //     Preview: YearPreview
    //   }
    // },
    gallery: {
      title: "Gallery",
      description: "Portfolio Item Gallery of Images",
      dataType: "array",
      config: {
        Preview: IllustrationsPreview
      },
      of: {
        dataType: "string",
        config: {
          storageMeta: {
            mediaType: "image",
            storagePath: "upload/portfolio/gallery",
            acceptedFiles: ["image/*"],
            fileName
          },
        }
      }
    },
    // gallery: {
    //   title: "Gallery",
    //   description: "Portfolio Item Gallery",
    //   dataType: "array",
    //   // config: {
    //   //   Preview: IllustrationsPreview
    //   // },
    //   of: {
    //     dataType: "map",
    //     properties: {
    //       type: {
    //         title: "Type",
    //         dataType: "string",
    //         config: {
    //           enumValues: {
    //             "image": "image",
    //             "video": "video",
    //           }
    //         }
    //       },
    //       media: ({ values }) => {
    //         if (values.type === "video") {
    //           return ({
    //             dataType: "string",
    //             title: "Youtube Video",
    //           });
    //         }
      
    //         return ({
    //           title: "Image",
    //           dataType: "string",
    //           validation: { required: true },
    //           config: {
    //             storageMeta: {
    //               mediaType: "image",
    //               storagePath: "upload/portfolio/gallery",
    //               acceptedFiles: ["image/*"],
    //               fileName
    //             },
    //           }
    //         });
    //       },
    //     }
    //   }
    // },
    sortOrder: {
      title: "Sort Order",
      description: "The greater the sort order the higher it shows",
      validation: {
        required: true,
        min: 0,
        integer: true
      },
      dataType: "number"
    }
  }
});
