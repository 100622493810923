import { buildSchema } from "@camberi/firecms";
import type { Page } from "types";
import { fileName } from "../helpers";
import SummaryPreview from "../components/SummaryPreview";

export default buildSchema<Page>({
  name: "Page",
  properties: {
    slug: {
      title: "Page",
      dataType: "string",
      config: {
        enumValues: {
          "homepage": "homepage",
          "about": "about",
          "portfolio-about": "portfolio-about",
        }
      }
    },
    image: ({ values }) => {
      // if (values.slug !== "about") {
      if (values.slug === "homepage") {
        return ({
          dataType: "string",
          title: "Image",
          disabled: {
            hidden: true,
            clearOnDisabled: true,
            tooltip: "Available only on About pages"
          }
        });
      }

      if (values.slug === "portfolio-about") {
        return ({
          title: "Image",
          dataType: "string",
          validation: { required: true },
          config: {
            storageMeta: {
              mediaType: "image",
              // storagePath: "upload/portfolio/about",
              storagePath: "upload/about",
              acceptedFiles: ["image/*"],
              fileName
            },
          }
        });
      }

      return ({
        title: "Image",
        dataType: "string",
        validation: { required: true },
        config: {
          storageMeta: {
            mediaType: "image",
            storagePath: "upload/about",
            acceptedFiles: ["image/*"],
            fileName
          },
        }
      });
    },
    illustrations: ({ values }) => {
      if (values.slug !== "homepage") {
        return ({
          dataType: "string",
          title: "Illustrations",
          disabled: {
            hidden: true,
            clearOnDisabled: true,
            tooltip: "Available only on Homepage"
          }
        });
      }
      return {
        title: "Illustrations",
        dataType: "array",
        of: {
          dataType: "string",
          config: {
            storageMeta: {
              mediaType: "image",
              storagePath: "upload/homepage",
              acceptedFiles: ["image/*"],
              fileName
            },
          }
        }
      }
    },

    bio: ({ values }) => {
      // if (values.slug !== "about") {
      if (values.slug === "homepage") {
        return ({
          dataType: "string",
          title: "Bio",
          disabled: {
            hidden: true,
            clearOnDisabled: true,
            tooltip: "Available only on About pages"
          }
        });
      }
      return {
        title: "Bio",
        validation: { required: true },
        dataType: "string",
        config: {
          markdown: true,
          Preview: SummaryPreview
        }
      }
    },

    email: ({ values }) => {
      if (values.slug !== "about") {
        return ({
          dataType: "string",
          title: "Email",
          disabled: {
            hidden: true,
            clearOnDisabled: true,
            tooltip: "Available only on About page"
          }
        });
      }
      return {
        title: "Email",
        validation: { required: true, email: true },
        description: "Email address",
        dataType: "string"
      }
    },

    links: ({ values }) => {
      if (values.slug !== "about") {
        return ({
          dataType: "string",
          title: "Links",
          disabled: {
            hidden: true,
            clearOnDisabled: true,
            tooltip: "Available only on About page"
          }
        });
      }
      return {
        title: "Links",
        dataType: "array",
        of: {
          dataType: "map",
          properties: {
            name: {
              title: "Name",
              description: "Link name (instagram, facebook, etc.)",
              validation: { required: true, uniqueInArray: true },
              dataType: "string"
            },
            url: {
              title: "URL",
              description: "URL of the link",
              validation: { required: true, url: true },
              dataType: "string"
            }
          }
        }
      }
    },

  }
});
