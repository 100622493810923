import type { AdditionalColumnDelegate } from "@camberi/firecms";
import { Avatar, Badge, Box, Divider, Stack } from "@mui/material";
import type { OrderDetails } from "types";

const orderDetailsColumn: AdditionalColumnDelegate<OrderDetails, string, any> = {
  id: "orderDetails",
  title: "Order Details",
  width: 520,
  builder: ({ entity }) => {
    const { items } = entity.values;
    const total: number = items.reduce((acc: number, i: any) => acc + i.quantity * i.item.price, 0);

    return (
      <Stack spacing={2}>
        {
          items.map(i => {
            const { quantity, item } = i;
            return (
              <Stack key={item.id} direction="row" spacing={2} alignItems="center">
                <Badge badgeContent={quantity} color="primary">
                  <Avatar
                    alt={item.id}
                    src={`https://kosturanov.com/${item.image.src}`}
                    variant="square"
                    sx={{ width: 56, height: 56 }}
                  />
                </Badge>
                <Box flexGrow={1}>
                  {item.title}
                </Box>
                <Box>
                  {new Intl.NumberFormat("en-US", { style: 'currency', currency: 'EUR' }).format(item.price)}
                </Box>
              </Stack>
            )
          })
        }
        <Divider />
        <Stack direction="row" spacing={2} alignItems="center">
          <Box />
          <Box flexGrow={1} />
          <Box>
            <strong>{new Intl.NumberFormat("en-US", { style: 'currency', currency: 'EUR' }).format(total)}</strong>
          </Box>
        </Stack>
      </Stack>
    )

  },
  dependencies: [
    "items"
  ]
}
export default orderDetailsColumn;
