import React from "react";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import logo from "./logo.svg"
import { FirebaseCMSApp, useInitialiseFirebase } from "@camberi/firecms";
import { authenticator, navigation, textSearchController, firebaseConfig } from "./cmsapp";

import "typeface-rubik";
import "typeface-space-mono";

export default function App() {

  const {
    firebaseApp,
    firebaseConfigLoading,
    configError,
    firebaseConfigError
  } = useInitialiseFirebase({ firebaseConfig });

  const onFirebaseInit = (config: Object) => {
    if (window.location.hostname === "localhost") {
      const db = getFirestore();
      connectFirestoreEmulator(db, 'localhost', 8080);

      const storage = getStorage();
      connectStorageEmulator(storage, 'localhost', 9199);
    }
  };

  return <FirebaseCMSApp
    name={"kosturanov.com"}
    authentication={authenticator}
    navigation={navigation}
    logo={logo}
    textSearchController={textSearchController}
    onFirebaseInit={onFirebaseInit}
    firebaseConfig={firebaseConfig}
  />;
}
