import { User as FirebaseUser } from "firebase/auth";
import { Authenticator } from "@camberi/firecms";

export const authenticator: Authenticator<FirebaseUser> = async ({
  user,
  authController
}) => {

  console.log("Allowing access to", user?.email);
  // This is an example of retrieving async data related to the user
  // and storing it in the user extra field.
  const sampleUserData = await Promise.resolve({
    roles: ["admin"]
  });
  authController.setExtra(sampleUserData);
  return true;
};
