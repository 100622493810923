import { buildEntityCallbacks, buildSchema, EntityOnDeleteProps, EntityOnSaveProps } from "@camberi/firecms";
import CustomImagePreview from "../components/CustomImagePreview";
import type { Print } from "types";
import { fileName } from "../helpers";
import SummaryPreview from "components/SummaryPreview";
import IllustrationsPreview from "components/IllustrationsPreview";

export default buildSchema<Print>({
  name: "Print",
  properties: {
    title: {
      title: "Title",
      validation: { required: true, unique: true },
      dataType: "string"
    },
    slug: {
      title: "Slug",
      validation: { required: true, unique: true },
      description: "Unique URL friendly identifier.",
      dataType: "string"
    },
    price: {
      title: "Price (€)",
      validation: { required: true },
      dataType: "number"
    },
    medium: {
      title: "Medium",
      validation: { required: true },
      dataType: "string"
    },
    size: {
      title: "Size",
      validation: { required: true },
      dataType: "string"
    },
    shippingInfo: {
      title: "Shipping info",
      validation: { required: true },
      dataType: "string",
      config: {
        markdown: true,
        Preview: SummaryPreview
      }
    },
    year: {
      title: "Year",
      validation: {
        required: true,
        min: 1995,
        max: (new Date()).getFullYear(),
        integer: true
      },
      dataType: "number"
    },
    edition: {
      title: "Edition",
      validation: {
        required: true,
        min: 0,
        integer: true
      },
      dataType: "number"
    },
    inStock: {
      title: "In Stock",
      validation: {
        required: true,
        min: 0,
        integer: true
      },
      dataType: "number"
    },
    details: {
      title: "Details",
      validation: { required: true },
      description: "Print details",
      dataType: "string"
    },
    image: {
      title: "Main Image",
      dataType: "string",
      validation: { required: true },
      description: "Main Image showing on the prints index page.",
      config: {
        Preview: CustomImagePreview,
        storageMeta: {
          mediaType: "image",
          storagePath: "upload/prints",
          acceptedFiles: ["image/*"],
          fileName
        }
      }
    },
    images: {
      title: "Gallery",
      dataType: "array",
      config: {
        Preview: IllustrationsPreview
      },
      of: {
        dataType: "string",
        config: {
          storageMeta: {
            mediaType: "image",
            storagePath: "upload/prints/gallery",
            acceptedFiles: ["image/*"],
            fileName
          },
        }
      }
    },
    sortOrder: {
      title: "Sort Order",
      description: "The greater the sort order value the higher it shows.",
      validation: {
        required: true,
        min: 0,
        integer: true
      },
      dataType: "number"
    }
  }
});


export const printCallbacks = buildEntityCallbacks({
  onPreSave: ({
    schema,
    path,
    entityId,
    values,
    status
  }: EntityOnSaveProps<Print>) => {
    console.log('BEGIN onPreSave ======');
    console.log({
      schema,
      path,
      entityId,
      values,
      status
    });
    console.log('END onPreSave ======');
    return values;
  },

  onSaveSuccess: (props: EntityOnSaveProps<Print>) => {
    console.log("onSaveSuccess", props);
  },

  onSaveFailure: (props: EntityOnSaveProps<Print>) => {
    console.log("onSaveFailure", props);
  },

  onPreDelete: ({
    schema,
    path,
    entityId,
  }: EntityOnDeleteProps<Print>) => {
    console.log('BEGIN onPreDelete ======');
    console.log({
      schema,
      path,
      entityId
    });
    console.log('END onPreDelete ======');
  },

  onDelete: (props: EntityOnDeleteProps<Print>) => {
    console.log("onDelete", props);
  },
});
