import type { AdditionalColumnDelegate } from "@camberi/firecms";
import { Box } from "@mui/material";
import type { OrderDetails } from "types";

const createdAtAdditionalColumn: AdditionalColumnDelegate<OrderDetails, string, any> = {
  id: "createdAtCustom",
  title: "Ordered At",
  width: 250,
  builder: ({ entity }) => (
    <Box>
      {new Intl.DateTimeFormat('en-US', {
        dateStyle: "medium",
        timeStyle: "short"
      }).format(entity.values.createdAt)}
    </Box>
  ),
  dependencies: [
    "createdAt"
  ]
}
export default createdAtAdditionalColumn;
