import { buildProperty, buildSchema, buildEntityCallbacks, EntityOnDeleteProps } from "@camberi/firecms";
import { fileName } from "../helpers";
import type { Category } from "types";

export default buildSchema<Category>({
  name: "Paintings Category",
  properties: {
    title: {
      title: "Title",
      validation: { required: true },
      dataType: "string"
    },
    slug: {
      title: "Slug",
      validation: { required: true },
      description: "Unique URL-friendly identifier. For example, for the paintings category 'Sky in Faces' the slug would be 'sky-in-faces'.",
      dataType: "string"
    },
    image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
      title: "Image",
      dataType: "string",
      description: "Featured Painting for the Catregory showing on the Paintings page",
      validation: { required: true },
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "upload/paintings/featured",
          acceptedFiles: ["image/*"],
          fileName
        },
      }
    }),
    sortOrder: {
      title: "Sort Order",
      description: "The greater the sort order the higher it shows — i.e. two categories with sortOrder 100 and 0 respectively, the one with '100' shows at the top.",
      validation: {
        required: true,
        min: 0,
        integer: true
      },
      dataType: "number"
    }
  }
});


export const categoryCallbacks = buildEntityCallbacks({
  onPreDelete: ({
    schema,
    path,
    entityId,
    entity,
    context
  }: EntityOnDeleteProps<Category>
  ) => {
    throw Error("Deletion not allowed!");
  }
});
