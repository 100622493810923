import { buildEntityCallbacks, buildSchema, EntityOnDeleteProps, EntityOnSaveProps } from "@camberi/firecms";
import CustomImagePreview from "../components/CustomImagePreview";
import type { OrderDetails } from "types";
import { fileName } from "../helpers";

export default buildSchema<OrderDetails>({
  name: "Order",
  properties: {
    firstName: {
      title: "First Name",
      validation: { required: true },
      dataType: "string",
      disabled: true,
    },
    lastName: {
      title: "lastName",
      validation: { required: true },
      dataType: "string",
      disabled: true,
    },
    address: {
      title: "Address",
      validation: { required: true },
      dataType: "string",
      disabled: true,
    },
    appartment: {
      title: "Appartment, suite etc.",
      dataType: "string",
      disabled: true,
    },
    postalCode: {
      title: "Postal Code",
      validation: { required: true },
      dataType: "string",
      disabled: true,
    },
    phone: {
      title: "Phone",
      validation: { required: true },
      dataType: "string",
      disabled: true,
    },
    city: {
      title: "City",
      validation: { required: true },
      dataType: "string",
      disabled: true,
    },
    country: {
      title: "Country",
      validation: { required: true },
      dataType: "string",
      disabled: true,
    },
    email: {
      title: "Email",
      validation: { required: true, email: true },
      dataType: "string",
      disabled: true,
    },
    paymentMethod: {
      title: "Payment Method",
      validation: { required: true },
      dataType: "string",
      disabled: true,
    },
    createdAt: {
      title: "Ordered At",
      disabled: true,
      dataType: "timestamp",
    },
    items: {
      title: "Order Items",
      dataType: "array",
      disabled: true,
      of: {
        dataType: "map",
        properties: {
          quantity: {
            title: "Quantity",
            validation: { required: true },
            disabled: true,
            dataType: "number"
          },
          item: {
            title: "Item",
            dataType: "map",
            disabled: true,
            properties: {
              title: {
                title: "Print",
                dataType: "string",
                disabled: true,
              },
              price: {
                title: "Price",
                dataType: "number",
                disabled: true,
              },
              image: {
                title: "",
                dataType: "map",
                properties: {
                  src: {
                    title: "",
                    dataType: "string",
                    disabled: true,
                    config: {
                      storageMeta: {
                        mediaType: "image",
                        storagePath: "public/prints",
                        acceptedFiles: ["image/*"]
                      },
                    }
                  }
                }
              }
            }
          },
        }
      }
    }
  }
});

