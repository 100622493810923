import type { AdditionalColumnDelegate } from "@camberi/firecms";
import { Box } from "@mui/material";
import type { OrderDetails } from "types";


const contactInformationAdditionalColumn: AdditionalColumnDelegate<OrderDetails, string, any> = {
  id: "contactInformation",
  title: "Contact Information",
  width: 420,
  builder: ({ entity }) => (
    <Box>
      <strong>{entity.values.firstName} {entity.values.lastName}</strong>
      <div>{entity.values.address} {entity.values.appartment}</div>
      <div>{entity.values.postalCode} {entity.values.city}</div>
      <div>{entity.values.country}</div>
    </Box>
  ),
  dependencies: [
    "email",
    "firstName",
    "lastName",
    "paymentMethod",
    "address",
    "appartment",
    "postalCode",
    "phone",
    "city",
    "country",
  ]
}
export default contactInformationAdditionalColumn;
