import { PreviewComponentProps } from "@camberi/firecms";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactElement } from "react";

export default function CustomSummaryPreview({ value, property, size }: PreviewComponentProps<string>): ReactElement {
  const useStyles = makeStyles({
    multiLineEllipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": (size === 'tiny' ? 1 : (size === 'small' ? 2 : 3)),
      "-webkit-box-orient": "vertical"
    }
  });

  const classes = useStyles();

  return <Box className={classes.multiLineEllipsis} title={value}>{value}</Box>
}
