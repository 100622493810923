import { buildSchema, buildEntityCallbacks, EntityOnDeleteProps } from "@camberi/firecms";
import type { PortfolioCategory } from "types";

export default buildSchema<PortfolioCategory>({
  name: "Portfolio Category",
  properties: {
    title: {
      title: "Title",
      validation: { required: true },
      dataType: "string"
    },
    slug: {
      title: "Slug",
      validation: { required: true },
      description: "Unique URL-friendly identifier. For example, for the portfolio category 'Logo & Branding Design' the slug could be 'logo-branding-design'.",
      dataType: "string"
    },
    sortOrder: {
      title: "Sort Order",
      description: "The greater the sort order the higher it shows — i.e. two categories with sortOrder 100 and 0 respectively, the one with '100' shows at the top.",
      validation: {
        required: true,
        min: 0,
        integer: true
      },
      dataType: "number"
    }
  }
});


export const categoryCallbacks = buildEntityCallbacks({
  onPreDelete: ({
    schema,
    path,
    entityId,
    entity,
    context
  }: EntityOnDeleteProps<PortfolioCategory>
  ) => {
    throw Error("Deletion not allowed!");
  }
});
