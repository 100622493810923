import { buildProperty, buildSchema } from "@camberi/firecms";
import type { Book } from "types";
import { fileName } from "../helpers";
import SummaryPreview from "../components/SummaryPreview";
import YearPreview from "../components/YearPreview";
import IllustrationsPreview from "../components/IllustrationsPreview";
// import PublishersPreview from "../components/PublishersPreview"

export default buildSchema<Book>({
  name: "Book",
  properties: {
    title: {
      title: "Title",
      validation: { required: true },
      dataType: "string"
    },
    slug: {
      title: "Slug",
      validation: { required: true },
      description: "Unique URL-friendly identifier. For example, for the book 'Start Child' the slug would be 'star-child'.",
      dataType: "string"
    },
    image: buildProperty({ // The `buildProperty` method is an utility function used for type checking
      title: "Image",
      dataType: "string",
      validation: { required: true },
      description: "Cover Image showing on the books index page, and on top of the specific book page",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "upload/books",
          acceptedFiles: ["image/*"],
          fileName
        },
      }
    }),
    summary: {
      title: "Summary",
      validation: { required: true },
      description: "Book summary",
      dataType: "string",
      config: {
        markdown: true,
        Preview: SummaryPreview
      }
    },
    date: {
      title: "Year",
      description: "Date (year) created",
      validation: { required: true },
      dataType: "timestamp",
      config: {
        Preview: YearPreview
      }
    },
    creators: {
      title: "Author(s)",
      dataType: "array",
      of: {
        dataType: "map",
        properties: {
          title: {
            title: "Type",
            description: "Author type",
            validation: { required: true, uniqueInArray: true },
            dataType: "string",
            config: {
              enumValues: {
                "Text & illustrations": "Text & illustrations",
                "Text": "Text",
                "Illustrations": "Illustrations"
              }
            }
          },
          name: {
            title: "Name",
            description: "Name of the author",
            validation: { required: true },
            dataType: "string"
          },
        }
      }
    },
    publishers: {
      title: "Publishers",
      dataType: "array",
      of: {
        dataType: "map",
        // config: {
        //   Preview: PublishersPreview
        // },
        properties: {
          date: {
            title: "Date",
            description: "Date (year) published",
            validation: { required: true },
            dataType: "timestamp",
            config: {
              Preview: YearPreview
            }
          },
          name: {
            title: "Name",
            description: "Name of the publisher",
            validation: { required: true },
            dataType: "string"
          },
          country: {
            title: "Country",
            description: "Country of the publisher",
            validation: { required: true },
            dataType: "string"
          },
        }
      }
    },
    awards: {
      title: "Awards",
      dataType: "array",
      of: {
        dataType: "string",
      }
    },
    illustrations: {
      title: "Illustrations",
      dataType: "array",
      config: {
        Preview: IllustrationsPreview
      },
      of: {
        dataType: "string",
        config: {
          storageMeta: {
            mediaType: "image",
            storagePath: "upload/books/illustrations",
            acceptedFiles: ["image/*"],
            fileName
          },
        }
      }
    },
    sortOrder: {
      title: "Sort Order",
      description: "The greater the sort order the higher it shows",
      validation: {
        required: true,
        min: 0,
        integer: true
      },
      dataType: "number"
    }
  }
});
