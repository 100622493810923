import { buildProperty, buildSchema } from "@camberi/firecms";
import type { Painting } from "types";
import { fileName } from "../helpers";
import YearPreview from "../components/YearPreview";

export default buildSchema<Painting>({
  name: "Painting",
  properties: {
    title: {
      title: "Title",
      validation: { required: true },
      dataType: "string"
    },
    slug: {
      title: "Slug",
      validation: { required: true },
      description: "Unique URL friendly identifier. For example, for the painting '9th Of September' the slug would be '9th-of-september'.",
      dataType: "string"
    },
    image: buildProperty({
      title: "Image",
      dataType: "string",
      validation: { required: true },
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "upload/paintings",
          acceptedFiles: ["image/*"],
          fileName
        },
      }
    }),
    description: {
      title: "Description",
      validation: { required: true },
      description: "Short painting description",
      dataType: "string"
    },
    date: {
      title: "Year",
      description: "Date (year) created",
      validation: { required: true },
      dataType: "timestamp",
      config: {
        Preview: YearPreview
      }
    },
    status: {
      title: "Status",
      validation: { required: true },
      dataType: "string",
      config: {
        enumValues: {
          "available for purchase": "available for purchase",
          "not available for purchase": "not available for purchase",
          "sold": "sold"
        }
      }
    },
    category: {
      title: "Category",
      dataType: "string",
      readOnly: true
    }
  }
});
