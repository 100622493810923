import algoliasearch, { SearchClient } from "algoliasearch";
import { performAlgoliaTextSearch, FirestoreTextSearchController } from "@camberi/firecms";

const client: SearchClient | undefined = algoliasearch("7V2JNTSS6P", "e874da2f979f5fdeedba25c5b5d2c76b");
const paintingsIndex = client.initIndex("prod_kosturanov_paintings");
const booksIndex = client.initIndex("prod_kosturanov_books");
const paintingsCategoriesIndex = client.initIndex("prod_kosturanov_paintings_categories");
const printsIndex = client.initIndex("prod_kosturanov_prints");

export const textSearchController: FirestoreTextSearchController = ({ path, searchString }) => {
  if (/^categories\/\w+\/paintings/.test(path))
    return performAlgoliaTextSearch(paintingsIndex, searchString);
  if (path === "books")
    return performAlgoliaTextSearch(booksIndex, searchString);
  if (path === "categories")
    return performAlgoliaTextSearch(paintingsCategoriesIndex, searchString);
  if (path === "prints")
    return performAlgoliaTextSearch(printsIndex, searchString);
  return undefined;
};

